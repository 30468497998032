// // PERMISOS.VUE

<template>
  <v-container id="view-permisos" v-if="Number($store.state.G.USUDAT.ndep)==9">
    <v-layout row wrap>
      <!-- Barra de cabecera -->
      <v-flex xs12>
        <v-card class="header" v-bind="ent_headerTitulo">
          <v-card-title class="titulo">{{
            ent_headerTitulo.titulo
          }}</v-card-title>
        </v-card>

        <v-card v-bind="ent_headerBtra">
          <v-card-actions class="header_btra">
            <v-btn
              v-bind="ent_headerBtra.btns"
              @click="guardar()"
              :disabled="clonando"
              >Guardar</v-btn
            >
            <v-btn
              v-bind="ent_headerBtra.btns"
              @click="cancelar()"
              :disabled="clonando"
              >Cancelar</v-btn
            >
            <v-btn
              v-bind="ent_headerBtra.btns"
              @click="clonar()"
              :disabled="clonando"
              >Clonar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>

      <!-- Menú -->
      <v-flex xs3>
        <modulos
          :itemsMenu="get_itemsMenu"
          :cfg="cfg.modulos"
          @update_clonando="update_clonando"
          @update_moduloSeleccionado="update_moduloSeleccionado"
        >
        </modulos>
      </v-flex>

      <!-- Departamentos -->
      <v-flex xs9>
        <departamentos
          ref="refDeptos"
          :modulo="find_modulo"
          :clonando="clonando"
          :cfg="cfg.deptos"
          @update_clonando="update_clonando"
        >
        </departamentos>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import plugs from "@/common/general_plugs";
import entorno from "@/common/entorno.json";
const modulos = () => plugs.groute("modulos.vue", "comp");
const departamentos = () => plugs.groute("departamentos.vue", "comp");

export default {
  components: { modulos, departamentos },
  data() {
    return {
      // variables de configuración
      cfg: entorno.permisos,
      ent_headerTitulo: entorno.permisos.header.header_titulo,
      ent_headerBtra: entorno.permisos.header.header_btra,

      // variables del componente
      modulos: [], // array de módulos del menú (solo los permitidos)
      moduloSeleccionado: null, // item del módulo seleccionado
      clonando: false, // true/false. Modo clonación o normal
      menu: this.$store.state.menu
    };
  },

  async created() {
    await this.get_modulos();
  },

  methods: {
    /* DATOS */
    async get_modulos() {
      // Obtengo todos los módulos de la app y los guardo en 'this.modulos'
      /* let param= {
        apiArg:['', 'permisos', { fnt:'', age:0, claves:'' }, "aplica"],
        args:{
          fnt:this.accionClave==1? 'set' : 'anular',
          age:this.masterField,
          claves:this.txt
        }
      }; */

      var args = {
        tipo: "",
        ruta: "libs",
        api: "permisos",
        accion: "getModules"
      };
      var apiResult = await this.$store.dispatch("apicall", { args: args });

      if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

      this.modulos = apiResult.r[0].map((item, key) => ({
        ...item,
        index: key,
        seleccionado: false,
        name: item.menu
      }));

    },

    /* EVENTOS */

    guardar() {
      // Guardo los cambios que hemos realizado en los permisos
      if (this.moduloSeleccionado === null) return;
      this.$refs.refDeptos.guardar();
    },

    cancelar() {
      // Cancelo los cambios que hemos realizado en los permisos
      if (this.moduloSeleccionado === null) return;
      this.$refs.refDeptos.cancelar();
    },

    clonar() {
      // Entro o salgo del modo clonación
      if (this.moduloSeleccionado === null) return;
      this.$refs.refDeptos.clonar();
    },

    update_moduloSeleccionado(modulo) {
      // Actualizo la variable 'modulo', que indica el módulo seleccionado
      this.moduloSeleccionado = modulo[0];
    },

    update_clonando(valor) {
      // Actualizo la variable 'clonando', que indica si estamos en modo clonación
      this.clonando = valor;
    }
  },

  computed: {
    get_itemsMenu() {
      // con el array de módulos monto un array anidado con los padres y sus hijos
      var itemsMenu = JSON.parse(JSON.stringify(this.modulos));

      itemsMenu.forEach(
        (it, key) =>
          (itemsMenu[key].pid =
            it.menuId.length === 2
              ? null
              : itemsMenu.find(elem => elem.menuId === it.menuId.slice(0, -2))
                  .menuId)
      );

      const anida = (items, menuId = null, link = "pid") =>
        items
          .filter(item => item[link] === menuId)
          .map(item => ({ ...item, children: anida(items, item.menuId) }));

      return anida(itemsMenu);
    },

    find_modulo() {
      // filtro por el módulo seleccionado
      if (this.moduloSeleccionado === null) return null;
      return this.moduloSeleccionado;
    }
  }
};
</script>

<style scope>
/* .header {
        color: whitesmoke;
        background-color: #00482b;
    } */

/* .body { display: flex; } */
</style>
